<template lang="">
    <div>
        <AddReminderComponent />
    </div>
</template>
<script>
export default {
    name:"AddReminder",
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Hatırlatma Ekle', route: '/hatirlatmalar' },
        ]);
    },
    components:{
        AddReminderComponent: () =>import('@/components/reminders/AddReminder.vue')
    }

}
</script>
<style lang="">
    
</style>